import isAfter from 'date-fns/isAfter'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import eachMinuteOfInterval from 'date-fns/eachMinuteOfInterval'
import {BusinessSchedule} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {DropdownOptionProps} from 'wix-ui-tpa/cssVars'
import {
  getHMFromShortTimeString,
  setHMToDate,
  getScheduleFromCalendarForDate,
  Calendar,
} from '@wix/table-reservations-lib/schedule'

import {getShortTime} from './dateString'
import {getScheduleForDate} from './businessSchedule'
import {roundUpToNearestQuarterTime, roundDownToNearestQuarterTime} from './date'

export type TimeOption = DropdownOptionProps & {
  data: {hours: number; minutes: number}
}

export const generateTimeOptionsBasedOnDaySchedule = (
  date: Date,
  schedule: Calendar[string]['schedule'],
  regionalSettings?: string,
): TimeOption[] => {
  let timeOptions: TimeOption[] = []
  const today = new Date()

  if (!date || !schedule) {
    return []
  }

  schedule.forEach(({open, close}) => {
    const [openHours, openMinutes] = getHMFromShortTimeString(open)
    const openDate = setHours(setMinutes(date, Number(openMinutes)), Number(openHours))
    const [closeHours, closeMinutes] = getHMFromShortTimeString(close)
    const closeDate = setHMToDate(date, closeHours, closeMinutes)

    if (openDate.getTime() >= closeDate.getTime()) {
      return
    }

    const timeIntervals = eachMinuteOfInterval(
      {
        start: roundDownToNearestQuarterTime(openDate),
        end: roundUpToNearestQuarterTime(closeDate),
      },
      {step: 15},
    ).filter(
      (timeInterval) =>
        openDate.getTime() <= timeInterval.getTime() &&
        timeInterval.getTime() <= closeDate.getTime(),
    )

    const futureTimeIntervals = timeIntervals.filter((d: Date) => isAfter(d, today))

    const options: TimeOption[] = futureTimeIntervals.map((d: Date) => {
      const time = getShortTime(d, regionalSettings)

      return {
        id: time,
        value: time,
        data: {
          hours: d.getHours(),
          minutes: d.getMinutes(),
        },
        isSelectable: true,
      }
    })

    timeOptions = [...timeOptions, ...options]
  })

  return timeOptions
}

export const getTimeOptions = ({
  date,
  businessSchedule,
  regionalSettings,
  timeZone,
}: {
  date: Date
  businessSchedule: BusinessSchedule
  regionalSettings?: string
  timeZone?: string | null
}) => {
  const businessScheduleForDate = getScheduleForDate({
    date,
    businessSchedule,
    timeZone,
  })
  const scheduleForDate = getScheduleFromCalendarForDate(date, businessScheduleForDate)

  return generateTimeOptionsBasedOnDaySchedule(date, scheduleForDate, regionalSettings)
}

export const findTimeOption = (timeOptions: TimeOption[], date: Date) =>
  timeOptions.find(
    ({data: {hours, minutes}}) => hours === date.getHours() && minutes === date.getMinutes(),
  )
